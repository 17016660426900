import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import Layout from '$layouts/main'
import HeaderSection from '$components/HeaderSection'
import OfficeAddresses from '$components/OfficeAddresses'

export default function Page() {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>
          {t('about_us')} - {process.env.REACT_APP_COMPANY_NAME}
        </title>
      </Helmet>
      <Layout>
        <HeaderSection
          title={t('about_us')}
          subtitle="Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.
          Elit sunt amet fugiat veniam occaecat fugiat aliqua."
        />
        <Content />
        <OfficeAddresses />
      </Layout>
    </>
  )
}

const Content = () => {
  const { t } = useTranslation()

  return (
    <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
      <div
        className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
        aria-hidden="true"
      />
      <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
          <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-3xl lg:col-span-2 xl:col-auto">
            {t('your_smile_is_our_passion')}
          </h1>
          <div className="mt-4 max-w-xl lg:mt-8 xl:col-end-1 xl:row-start-1 space-y-10">
            <p className="text-lg leading-8 text-gray-600">
              Köln ve Varşova merkezli, alkolsüz meşrubat ve kağıt ambalaj malzemelerinin
              dağıtımında uzmanlaşmış genç ve yenilikçi bir şirketiz. Köln'deki merkez ofisimizden
              Almanya, Fransa, Belçika ve Hollanda pazarlarına yönelik tüm işlerimizi koordine
              ediyoruz. Varşova'dan ise Polonya, Rusya ve Çek Cumhuriyeti'ndeki müşterilerimizle
              ilgileniyoruz.
            </p>
            <p className="text-lg leading-8 text-gray-600">
              Onlarca yıllık ticari deneyimimize güveniyor ve nesillerdir aile şirketi olan
              üreticilerle çalışıyoruz. Bu bize ortaklarımızın işlerine hala tutkuyla bağlı
              olduklarından ve ürünlerini her zaman en yüksek standartlarda ürettiklerinden emin
              olmamızı sağlıyor.
            </p>
            <p className="text-lg leading-8 text-gray-600">
              Ekibimiz, müşterilerimize her zaman yüksek kaliteli çözümler ve mükemmel hizmet sunmak
              için çaba gösteren kendini işine adamış uzmanlardan oluşmaktadır. Alkolsüz meşrubat ve
              kağıt ambalaj malzemelerine olan tutkumuz ve mükemmellik arayışımızla, müşterilerimize
              her zaman en iyisini sunmaktan gurur duyuyoruz. NRW-Almanya'nın tanınmış markası Niğde
              Gazozu'nun dağıtım haklarını alarak ortak bir hedef için yeni bir temel atıyoruz.
            </p>
            <p className="text-lg leading-8 text-gray-600">
              Felsefemiz, Alman erdemleri ve güney cazibesinin bir dokunuşuyla çalışmaktır. Sonuçta
              bizi özel bir ortak yapan da bu kombinasyondur.
            </p>
          </div>
          <img
            src="https://images.unsplash.com/photo-1567532900872-f4e906cbf06a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1280&q=80"
            alt=""
            className="mt-4 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
          />
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
    </div>
  )
}
