import type { Params } from 'react-router-dom'
import { createBrowserRouter } from 'react-router-dom'

import NotFoundPage from '$pages/not-found'
import HomePage from '$pages/home'
import AboutPage from '$pages/about'
import ProductsPage from '$pages/products'
import ContactPage from '$pages/contact'
import i18n from '$utils/i18n'

const i18nLoader = async ({ params: { lang } }: { params: Params<string> }) => {
  if (lang) {
    const isIncludes = i18n.languages.indexOf(lang) > -1
    if (!isIncludes) {
      throw new Response('', {
        status: 404,
        statusText: 'Not Found',
      })
    }

    await i18n.changeLanguage(lang)
  }

  return null
}

const router = createBrowserRouter([
  {
    path: '/',
    Component: HomePage,
  },
  {
    path: '/:lang',
    Component: HomePage,
    loader: i18nLoader,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/:lang/contact',
    Component: ContactPage,
    loader: i18nLoader,
  },
  {
    path: '/:lang/about',
    Component: AboutPage,
    loader: i18nLoader,
  },
  {
    path: '/:lang/products',
    Component: ProductsPage,
    loader: i18nLoader,
  },
  {
    path: '/:lang/products/:category',
    Component: ProductsPage,
    loader: i18nLoader,
  },
  {
    path: '*',
    Component: NotFoundPage,
    loader: i18nLoader,
  },
])

export default router
