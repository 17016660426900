import { useState, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { getI18n, useTranslation } from 'react-i18next'
import { MapPinned, Clock, Phone, Menu, CircleX } from 'lucide-react'

import cn from '$utils/classnames'

export default function Header() {
  const location = useLocation()
  const i18n = getI18n()
  const { t } = useTranslation()

  const address = useMemo(() => {
    const lang = i18n.language.toUpperCase()
    const value = process.env[`REACT_APP_ADDRESS_${lang}`]
    return value ?? process.env.REACT_APP_ADDRESS
  }, [i18n.language])

  const workingHours = useMemo(() => {
    const lang = i18n.language.toUpperCase()
    const value = process.env[`REACT_APP_WORKING_HOURS_${lang}`]
    return value ?? process.env.REACT_APP_WORKING_HOURS
  }, [i18n.language])

  const phoneNumber = useMemo(() => {
    const lang = i18n.language.toUpperCase()
    const value = process.env[`REACT_APP_PHONE_NUMBER_${lang}`]
    return value ?? process.env.REACT_APP_PHONE_NUMBER
  }, [i18n.language])

  const languages = useMemo(
    () => [
      {
        name: 'tr',
        href: '/tr',
        flag: '/assets/flags/turkiye.svg',
      },
      {
        name: 'en',
        href: '/en',
        flag: '/assets/flags/usa.svg',
      },
      {
        name: 'de',
        href: '/de',
        flag: '/assets/flags/germany.svg',
      },
    ],
    []
  )

  const nav = useMemo(() => {
    const { pathname } = location || {}
    const path = pathname
      .split(/(\/[a-zA-Z0-9_-]+)/)
      .filter((i) => !!i)
      .slice(1)
      .join('')

    return [
      { name: 'home', href: '', current: path === '/' || path === '' },
      { name: 'about_us', href: 'about', current: path === '/about' },
      { name: 'products', href: 'products', current: path === '/products' },
      { name: 'softdrinks', href: 'products/softdrinks', current: path === '/products/softdrinks' },
      { name: 'papers', href: 'products/papers', current: path === '/products/papers' },
      { name: 'contact', href: 'contact', current: path === '/contact' },
    ]
  }, [location])

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="w-full h-36 bg-gradient-to-r from-black from-50% to-white to-50%">
      <div className="container flex h-full max-w-7xl mx-auto bg-white">
        <div className="relative h-full px-10 aspect-square bg-black after:content-empty after:absolute after:z-30 after:top-0 after:-right-16 after:w-16 after:h-36 after:bg-gradient-to-tr after:from-black after:from-50% after:to-transparent after:to-0%">
          <div className="mt-6 w-24 h-24">
            <img className="w-full h-full" src="/logo.svg" alt="Bulutlar GmbH" />
          </div>
        </div>
        <div className="flex flex-col justify-center lg:mt-auto lg:justify-start w-full">
          <div className="flex mr-4 space-x-4 justify-end py-4 lg:space-x-8 lg:mr-0 border-b border-gray-200">
            <div className="hidden lg:flex items-center">
              <MapPinned className="w-4 h-4 mr-2" />
              <span className="text-xs">{address}</span>
            </div>
            <div className="hidden lg:flex items-center">
              <Clock className="w-4 h-4 mr-2" />
              <span className="text-xs">{workingHours}</span>
            </div>
            <div className="flex items-center">
              <Phone className="w-4 h-4 mr-2" />
              <span className="text-xs">{phoneNumber}</span>
            </div>
            <div className="flex gap-x-2">
              {languages.map((item) => (
                <Link to={item.href} key={item.name}>
                  {item.flag && <img className="h-5" src={item.flag} alt={item.name} />}
                </Link>
              ))}
            </div>
          </div>
          <div className="flex justify-end p-8 lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <span className="sr-only">Open main menu</span>
              {mobileMenuOpen ? (
                <CircleX className="h-6 w-6" aria-hidden="true" />
              ) : (
                <Menu className="h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
          <nav className="hidden lg:flex lg:justify-end lg:space-x-8 lg:py-8" aria-label="Global">
            {nav.map((item) => (
              <Link
                key={item.name}
                to={`/${i18n.language.replace(/-\w+/, '')}/${item.href}`}
                className={cn(
                  item.current
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                  'inline-flex items-center rounded-md py-2 px-3 text-sm font-semibold'
                )}
                aria-current={item.current ? 'page' : undefined}
              >
                {t(item.name)}
              </Link>
            ))}
          </nav>
          {mobileMenuOpen && (
            <div className="absolute lg:hidden top-36 left-0 right-0 z-10 overflow-y-auto bg-white py-4">
              <div className="divide-y divide-gray-500/10">
                <div className="space-y-2">
                  {nav.map((item) => (
                    <Link
                      key={item.name}
                      to={`/${i18n.language.replace(/-\w+/, '')}/${item.href}`}
                      className="block px-6 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {t(item.name)}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
