import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import EnTranslations from '$translations/en.json'
import DeTranslations from '$translations/de.json'
import TrTranslations from '$translations/tr.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV !== 'production',
    fallbackLng: 'en',
    detection: {
      order: ['cookie', 'localStorage', 'navigator'],
      caches: ['cookie'],
      lookupCookie: 'lang',
      lookupLocalStorage: 'lang',
      cookieMinutes: 99999,
      cookieOptions: { path: '/', sameSite: 'lax' },
    },
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: EnTranslations,
      },
      de: {
        translation: DeTranslations,
      },
      tr: {
        translation: TrTranslations,
      },
    },
  })

i18n.languages = ['tr', 'en', 'de']

export default i18n
