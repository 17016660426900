import { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LazyLoadImage as Image } from 'react-lazy-load-image-component'

import Layout from '$layouts/main'
import HeaderSection from '$components/HeaderSection'

export default function Page() {
  const location = useLocation()
  const { t } = useTranslation()

  const category = useMemo(() => {
    const { pathname } = location || {}
    const categoryName = pathname.endsWith('/softdrinks')
      ? 'softdrinks'
      : pathname.endsWith('/papers')
      ? 'papers'
      : 'products'

    return categoryName
  }, [location])

  return (
    <>
      <Helmet>
        <title>
          {t(category)} - {process.env.REACT_APP_COMPANY_NAME}
        </title>
      </Helmet>
      <Layout>
        <HeaderSection
          title={t(category)}
          subtitle="Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.
          Elit sunt amet fugiat veniam occaecat fugiat aliqua."
        />
        {['products', 'softdrinks'].includes(category) && <Softdrinks />}
        {['products', 'papers'].includes(category) && <Papers />}
      </Layout>
    </>
  )
}

const Softdrinks = () => {
  const { t } = useTranslation()

  const images = ['/assets/products/softdrinks-nigde-gazozu-01.jpeg']

  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900">{t('softdrinks')}</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Varius facilisi mauris sed sit. Non sed et duis dui leo, vulputate id malesuada non.
            Cras aliquet purus dui laoreet diam sed lacus, fames.
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {images.map((image, index) => (
            <div key={index}>
              <Image
                className="h-auto max-w-full rounded-lg"
                alt={`gallery-image-${index}`}
                src={image}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const Papers = () => {
  const { t } = useTranslation()

  const images = [
    ...new Array(10)
      .fill('')
      .map(
        (_, i) =>
          `/assets/products/papers-nehir-kagitcilik-${(i + 1).toString().padStart(2, '0')}.jpg`
      ),
  ]

  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900">{t('papers')}</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Varius facilisi mauris sed sit. Non sed et duis dui leo, vulputate id malesuada non.
            Cras aliquet purus dui laoreet diam sed lacus, fames.
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {images.map((image, index) => (
            <div key={index}>
              <Image
                className="h-auto max-w-full rounded-lg"
                alt={`gallery-image-${index}`}
                src={image}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
