import { Helmet } from 'react-helmet'

import Layout from '$layouts/main'
import HeroSection from '$components/HeroSection'
import CTASection from '$components/CTASection'

export default function Page() {
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_COMPANY_NAME}</title>
      </Helmet>
      <Layout>
        <HeroSection />
        <CTASection />
      </Layout>
    </>
  )
}
